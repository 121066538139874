<template lang="pug">
.custom-registration-wrapper(
    :class='{ "is-academy-signup": isSachkundeCompany }',
    data-cy='custom-registration'
)
    AcademySignup(v-if='isSachkundeCompany')
    OtherAcademiesSignup(v-else)
</template>

<script setup lang="ts">
  import useCommonMixin from '@/composables/useCommonMixin'
  import OtherAcademiesSignup from '@/components/signup/OtherAcademiesSignup.vue'
  import AcademySignup from '@/components/signup/AcademySignup.vue'

  const { isSachkundeCompany } = useCommonMixin()
</script>

<style lang="postcss">
  .custom-registration-wrapper {
    @apply ketch-flex ketch-flex-col ketch-justify-center;
  }
</style>
