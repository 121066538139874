<template lang="pug">
.profile-page
    LoadingSpinner(v-if='!canSetUserProfile')
    .profile-page-wrapper(v-else)
        .logo
            span(:class='{ "has-theme-url": logoUrl }')
                img(:src='logoUrl || smallLogo')
        .top-copy
            h3.title {{ $t('onboarding.profilePage.pageTitle', { company: companyName }) }}
            h3.description {{ $t('onboarding.profilePage.pageDescription') }}
        AcademyProfileUpdateForm(
            :first-name='UserModule.firstName || ""',
            :last-name='UserModule.lastName || ""',
            @loading-company-profile-questions='loadingCompanyProfileQuestions = $event'
        )
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { UserModule } from '@/store/modules/user'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import eventBus from '@/main'
  import AcademyProfileUpdateForm from '@/components/signup/AcademyProfileUpdateForm.vue'

  const { smallLogo } = useIcons()
  const { setIsLoadingComponentViewData, logoUrl, is34ACompany } = useCommonMixin()
  const { fetchAllCourseModules } = useCourse()

  const loadingCompanyProfileQuestions = ref(true)

  const auth0Id = computed((): string | null => {
    return UserModule.auth0Id
  })

  const companyName = computed(() => UserModule.currentCompany?.name || 'KetchUp')

  const canSetUserProfile = computed(() => {
    if (auth0Id.value === null || !UserModule.email) return false
    return !UserModule.onboarded
  })

  const courseSectionsFetched = computed(() => CourseModule.courseSectionsAssignedCourses)

  watch(courseSectionsFetched, (value) => {
    if (value) {
      if (is34ACompany.value) {
        fetchAllCourseModules(CourseModule.courseSectionsAssignedCourses?.map((c) => c.id) ?? [])
      }
      eventBus.$emit('turn-off-loader')
    }
  })

  if (courseSectionsFetched.value) {
    eventBus.$emit('turn-off-loader')
  } else {
    eventBus.$emit('turn-on-loader')
  }

  onMounted(async () => {
    setIsLoadingComponentViewData(false)
    if (UserModule.onboarded) {
      window.location.href = '/'
    }
  })
</script>

<style lang="postcss">
  .profile-page {
    @apply ketch-flex ketch-flex-col ketch-items-center ketch-w-full ketch-justify-center ketch-py-c30;
    .loading {
      @apply ketch-mx-auto;
    }
    .profile-page-wrapper {
      @apply ketch-max-w-[400px];
      .error-message {
        @apply ketch-mt-c10 ketch-text-red-500 ketch-px-c8 ketch-py-c2 ketch-border ketch-border-red-200;
        @apply ketch-rounded-small ketch-bg-red-100;
      }
      .logo {
        @apply ketch-flex ketch-justify-center;
        span {
          img {
            @apply ketch-max-h-[40px];
          }
          &:not(.has-theme-url) {
            @apply ketch-bg-white ketch-inline-flex ketch-items-center ketch-justify-center ketch-rounded-full ketch-w-c40 ketch-h-c40;
            img {
              @apply ketch-w-c20;
            }
          }
        }
      }
      .top-copy {
        @apply ketch-text-center;
        .title {
          @apply ketch-font-bold ketch-mt-c35 ketch-mb-c10;
        }
        .description {
          @apply ketch-text-gray-470;
        }
      }
    }
  }
</style>
