<script setup lang="ts">
  import { computed, onMounted, reactive, ref, watch } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import ProfileQuestion from '@/components/ProfileQuestion.vue'
  import LanguageTranslateToggle from '@/components/common/LanguageTranslateToggle.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import UsersApi from '@/services/api/UsersApi'
  import type { CompanyProfileQuestion, UserUtmParameters } from '@/services/interfaces/Auth0'
  import { useRoute, useRouter } from 'vue-router/composables'
  import type { QuestionAnswer } from '@/services/interfaces/QuizQuestion'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useIcons from '@/composables/useIcons'
  import { UserModule } from '@/store/modules/user'
  import CookieHelper from '@/helpers/CookieHelper'
  import eventBus from '@/main'
  import useSegment from '@/composables/useSegment'
  import { CourseModule } from '@/store/modules/course'
  import useI18n from '@/composables/useI18n'
  import useCourse from '@/composables/useCourse'
  import useAuthentication from '@/composables/useAuthentication'
  import AcademyProfileUpdateFormLoader from '@/components/loaders/AcademyProfileUpdateFormLoader.vue'
  import * as Sentry from '@sentry/vue'
  import { DateTime } from 'luxon'
  import type { Nullable } from '@/services/interfaces/Content'

  const route = useRoute()
  const router = useRouter()

  const {
    setPhoneOrTextAnswer,
    onAnswerChoiceClick,
    profileQuestionIsUnfilled,
    showTranslateSelector,
    languageTranslations,
    nextOnboardingPage,
    is34ACompany,
    subDomainFromUrl,
    redirectToNewDomain,
    highlightedCourseBundlePrefix,
  } = useCommonMixin()
  const { checkedCheckboxSquareWhite, checkboxSquareWhite } = useIcons()
  const { frontendUserSignedUp, trackPage, pageViewed } = useSegment()
  const { translateString } = useI18n()
  const { hasPurchasedAtLeastACourse, handle34ANextOnboardingAction } = useCourse()
  const { tokenKey } = useAuthentication()

  const props = defineProps({
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
  })

  const emit = defineEmits(['loading-company-profile-questions', 'routing-to-next-onboarding-page'])

  const formUser: { firstName: string | null; lastName: string | null } = reactive({
    firstName: props.firstName || null,
    lastName: props.lastName || null,
  })
  const selectedAnswers = ref([] as QuestionAnswer[])
  const companyProfileQuestions = ref([] as CompanyProfileQuestion[])
  const mandatoryQuestionIds = ref([] as string[])
  const loadingCompanyProfileQuestions = ref(true)
  const selectedTermsAndPrivacy = ref(false)
  const showErrorText = ref(false)
  const submittingProfileDetails = ref(false)
  const firstNameInput = ref(null as Nullable<HTMLInputElement>)

  const structuredCompanyProfileQuestions = computed(() => {
    return companyProfileQuestions.value?.map((companyProfileQuestion) => ({
      position: companyProfileQuestion.position,
      mandatory: companyProfileQuestion.mandatory,
      ...companyProfileQuestion.profileQuestion,
    }))
  })

  const mandatoryQuestionsFilled = computed(() => {
    return mandatoryQuestionIds.value.every((qId) => {
      const profileQuestion = structuredCompanyProfileQuestions.value.find((_q) => _q.id === qId)
      return !profileQuestionIsUnfilled.value(selectedAnswers.value, profileQuestion!)
    })
  })

  const hasNotFilledOutAllFields = computed((): boolean => {
    return !(formUser.firstName && formUser.lastName && selectedTermsAndPrivacy.value && mandatoryQuestionsFilled.value)
  })

  const companyId = computed(() => {
    const { testDomain } = route.query

    if (testDomain) {
      return testDomain === 'staging' ? '7573f9d0-2995-44c3-aa8f-b032e97c1a2d' : 'f9cace57-ecb2-4852-a108-9f37568b7235'
    }

    return subDomainFromUrl.value ? UserModule.currentCompany?.id : 'e8137522-4f13-4eed-8198-6ef9ee0845ee'
  })

  const setAnswer = (questionId: string, text: string, answerType: 'phone' | 'text') => {
    setPhoneOrTextAnswer(selectedAnswers.value, questionId, text, answerType)
  }

  const submitProfile = async () => {
    submittingProfileDetails.value = true

    try {
      await UsersApi.updateOnboardingUser(
        {
          auth0Id: UserModule.auth0Id,
          firstName: formUser.firstName!,
          lastName: formUser.lastName!,
        },
        selectedAnswers.value,
        true,
      )

      // TODO remove this when all signup flows is combined into one
      await CookieHelper.removeCrossDomainCookie('is-academy-signup')
      emit('routing-to-next-onboarding-page')
      await UserModule.getUser()
      await frontendUserSignedUp(UserModule.email)

      if (window.opener) window.opener.postMessage(CookieHelper.getCookieValue(tokenKey.value), '*')
      eventBus.$emit('reset-background-tile-height')
      submittingProfileDetails.value = false
      nextAction()
    } catch (error) {
      eventBus.$toasted.error(translateString('onboarding.profilePage.profileUpdateUnsuccessful'))
    }
  }

  const nextAction = () => {
    if (!hasPurchasedAtLeastACourse.value && nextOnboardingPage.value('ProfilePage')) {
      if (nextOnboardingPage.value('ProfilePage') === 'ExamDatePicker') {
        // route to homepage since ExamDatePicker is a modal now
        window.location.href = '/'
      } else if (nextOnboardingPage.value('ProfilePage') !== route.name) {
        if (highlightedCourseBundlePrefix.value) {
          router
            .push({
              name: nextOnboardingPage.value('ProfilePage'),
              query: { bundlePrefix: highlightedCourseBundlePrefix.value },
            })
            .catch(() => {
              return
            })
        } else {
          router.push({ name: nextOnboardingPage.value('ProfilePage') }).catch(() => {
            return
          })
        }
      }
    } else {
      if (is34ACompany.value && CourseModule.courseSectionsAssignedCourses?.length === 1) {
        // if there's only one course assigned to the users of 34a we set the custom next action journey
        handle34ANextOnboardingAction()
      } else {
        window.location.href = '/'
      }
    }
  }

  const getCompanyProfileQuestions = () => {
    UsersApi.getCompanyProfileQuestions()
      .then((questions) => {
        companyProfileQuestions.value = questions
      })
      .finally(() => {
        loadingCompanyProfileQuestions.value = false
        emit('loading-company-profile-questions', false)
        setMandatoryQuestions()
        if (route.query.testDomain) {
          prefillSelectedAnswers()
        }
      })
  }

  const setMandatoryQuestions = () => {
    mandatoryQuestionIds.value =
      structuredCompanyProfileQuestions.value?.filter((q) => q.mandatory).map((_q) => _q.id) ?? []
  }

  const prefillSelectedAnswers = () => {
    // prefill for test user
    selectedAnswers.value = [
      {
        questionId: 'bd43b6a9-00af-4dac-8530-9d178ed3651a',
        selectedAnswerIds: ['2bcae7fb-0f96-4885-96b3-10e49e309449'],
      },
      {
        questionId: '9b2fb1a6-1377-4d1b-8741-0bf418f51da3',
        selectedAnswerIds: ['6fef97ec-f93c-4037-9140-ce1787968d35'],
      },
      {
        questionId: '23e620bb-565e-4fda-9397-03ae40d77dd6',
        selectedAnswerIds: ['5a94ba03-0d86-4a94-84f9-ea975bc12ee2'],
      },
    ]
  }

  const assignUserToCompany = () => {
    if (companyId.value) {
      if (UserModule.companies.length === 1) {
        if (
          subDomainFromUrl.value !== UserModule.companies[0].subDomain &&
          ['production', 'qa'].includes(process.env.APP_ENV!)
        ) {
          redirectToNewDomain(UserModule.companies[0].subDomain)
        }
      } else if (UserModule.auth0Id && !UserModule.companies.some((c) => c.id === companyId.value)) {
        UsersApi.updateOnboardingUser(
          {
            auth0Id: UserModule.auth0Id,
            firstName: UserModule.firstName,
            lastName: UserModule.lastName,
          },
          [],
          false,
          companyId.value,
          //adding a catch block to handle validation errors where user already exists for same company
          // eslint-disable-next-line @typescript-eslint/no-empty-function
        ).catch(() => {})
      }
      CookieHelper.removeCrossDomainCookie('company_subdomain_details')
    }
  }

  const updateUserUtmParams = async () => {
    try {
      if (!UserModule.auth0Id) await UserModule.getUser()
      const extractedParams: { [key in UserUtmParameters]?: string } = {}

      const urlParams = new URLSearchParams(window.location.search)
      const userUtmParameters: UserUtmParameters[] = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_lp',
        'utm_adgroup',
        'gclid',
        'gbraid',
        'wbraid',
        'msclkid',
      ]

      userUtmParameters.forEach((param) => {
        if (param === 'utm_lp') {
          /* Handle multiple utm_lp parameters
               and filter out the one with v1
             */
          const paramValues = urlParams.getAll(param)
          const validParamValues = paramValues.filter((value) => value !== 'v1')

          if (validParamValues.length > 0) {
            extractedParams[param] = decodeURIComponent(validParamValues[0])
          }
        } else {
          const paramValue = urlParams.get(param)
          if (paramValue) {
            extractedParams[param] = decodeURIComponent(paramValue)
          }
        }
      })

      if (Object.keys(extractedParams).length > 0 && UserModule.auth0Id) {
        identifyUserInSegment(extractedParams)
        await UsersApi.updateUserUtmParameters(UserModule.auth0Id, extractedParams)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const identifyUserInSegment = (utmParameters: { [key in UserUtmParameters]?: string }): void => {
    const { analytics } = window as any
    if (analytics) {
      const identifyUserObject: any = {
        name: UserModule.fullname,
        email: UserModule.email,
        company: UserModule.currentCompany?.name || '',
        created_at: DateTime.fromSQL(UserModule.createdAt),
        ...utmParameters,
      }

      analytics.identify(UserModule.auth0Id, identifyUserObject)
    }
  }

  const setAcademySignupCookie = () => {
    if (route.name !== 'CustomRegistration') return
    const isAcademySignup = CookieHelper.getCookieValue('is-academy-signup')
    if (!UserModule.onboarded && !isAcademySignup) {
      CookieHelper.setCrossDomainCookie('is-academy-signup', 'true')
    }
  }

  const trackPageViewed = () => {
    if (UserModule.hasValidUser && !UserModule.onboarded) {
      if (route.name === 'CustomRegistration') {
        trackPage('Academy Profile Page')
        pageViewed('Academy Profile Page')
      } else {
        trackPage('Profile Page')
        pageViewed('Profile Page')
      }
    }
  }

  watch(
    () => UserModule.auth0Id,
    (value) => {
      if (value) {
        assignUserToCompany()
      }
    },
  )

  onMounted(() => {
    if (route.query.accessToken && !CookieHelper.getCookieValue(tokenKey.value)) {
      CookieHelper.setCrossDomainCookie(tokenKey.value, route.query.accessToken as string)
    }

    assignUserToCompany()
    trackPageViewed()
    setAcademySignupCookie()
    getCompanyProfileQuestions()
    updateUserUtmParams()
  })
</script>

<template lang="pug">
.academy-onboarding-wrapper
  AcademyProfileUpdateFormLoader(v-if='loadingCompanyProfileQuestions')
  template(v-else)
    .your-details
      .name-wrapper
        .name-input
          .name-input-container
            h3.required-input {{ $t('onboarding.profilePage.firstNamePlaceholder') }}
            input.h3(
              :placeholder='$t("onboarding.profilePage.firstNamePlaceholder")',
              data-cy='firstName',
              ref='firstNameInput',
              type='text',
              v-model='formUser.firstName'
            )
          .name-input-container
            h3.required-input {{ $t('onboarding.profilePage.lastNamePlaceholder') }}
            input.h3(
              :placeholder='$t("onboarding.profilePage.lastNamePlaceholder")',
              data-cy='lastName',
              type='text',
              v-model='formUser.lastName'
            )
        .error-wrapper(v-if='showErrorText')
          h5.error-message(v-if='!(formUser.firstName && formUser.lastName)') {{ $t('onboarding.errors.fillInYourName') }}
    .profile-questions-wrapper(v-if='structuredCompanyProfileQuestions && structuredCompanyProfileQuestions.length')
      ProfileQuestion(
        :key='question.id',
        :on-answer-choice-click='onAnswerChoiceClick',
        :question='question',
        :selected-answers='selectedAnswers',
        :set-answer='setAnswer',
        v-for='question in structuredCompanyProfileQuestions'
      )
    .language-selector-wrapper(v-if='showTranslateSelector && languageTranslations && languageTranslations.length')
      h3 {{ $t('onboarding.profilePage.chooseLanguage') }}
      LanguageTranslateToggle
    .terms-wrapper
      .terms
        .svg-wrapper(
          @click='selectedTermsAndPrivacy = !selectedTermsAndPrivacy',
          data-cy='acceptTerms'
        )
          SVGRenderer(
            :has-hover='false',
            :icon='selectedTermsAndPrivacy ? checkedCheckboxSquareWhite : checkboxSquareWhite',
            stroke-color='var(--primary-text-color)'
          )
        h6.accepted-terms-title
          span(@click='selectedTermsAndPrivacy = !selectedTermsAndPrivacy') {{ $t('onboarding.agreeWith') }}
          a(
            href='https://www.ketchup.academy/nutzungsbedingungen',
            target='_blank'
          ) {{ $t('onboarding.termsOfService') }}
          span(@click='selectedTermsAndPrivacy = !selectedTermsAndPrivacy') {{ $t('onboarding.andthe') }}
          a(
            href='https://www.ketchup.academy/privacy',
            target='_blank'
          ) {{ $t('onboarding.privacyPolicy') }}
      .error-wrapper(v-if='showErrorText')
        h5.error-message(v-if='!selectedTermsAndPrivacy') {{ $t('generalErrorMessages.acceptTermsOfService') }}
    KetchUpButton.primary(
      :disabled='hasNotFilledOutAllFields',
      @click.native='submitProfile',
      data-cy='submitProfile'
    )
      LoadingSpinner(v-if='submittingProfileDetails')
      template(v-else)
        h5 {{ $t('letsGo') }}
</template>

<style scoped lang="postcss">
  .academy-onboarding-wrapper {
    @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-p-c20;
    h3.required-input::after {
      content: ' *';
      color: red;
    }
    .your-details {
      .name-wrapper {
        .name-input {
          @apply ketch-flex ketch-flex-col ketch-space-y-c15;
          @apply xs3:ketch-flex-row xs3:ketch-space-y-0 xs3:ketch-items-center xs3:ketch-space-x-c10;
          .name-input-container {
            @apply ketch-flex ketch-flex-col ketch-space-y-c10;
          }
          input {
            @apply ketch-border ketch-border-border-color ketch-bg-transparent ketch-rounded-small;
            @apply ketch-px-c15 ketch-py-c10 ketch-outline-none ketch-w-full focus:ketch-border-gray-700;
          }
        }
      }
    }
    .profile-questions-wrapper {
      @apply ketch-flex ketch-flex-col ketch-space-y-c15 ketch-mb-c50;
    }
    .language-selector-wrapper {
      @apply ketch-flex ketch-flex-col ketch-space-y-c15;
    }
    .terms-wrapper {
      @apply ketch-flex ketch-flex-col ketch-my-c30;
      .terms {
        @apply ketch-flex;
        svg {
          @apply ketch-mr-c10 ketch-cursor-pointer;
        }
        .svg-wrapper {
          @apply ketch-w-c20 ketch-mr-c10 ketch-h-c20;
        }
        .accepted-terms-title {
          @apply ketch-space-x-c5 ketch-cursor-pointer;
          span {
            @apply ketch-text-gray-470 ketch-inline-block;
          }
          a {
            @apply ketch-underline ketch-inline-block;
          }
        }
      }
    }
    button.primary {
      @apply ketch-w-full ketch-h-c40 ketch-rounded-normal;
    }
  }
</style>
