<template lang="pug">
.signup-wrapper
    form.signup-form(@submit.prevent='signupDirectConnection')
        .form-wrapper(data-cy='signupForm')
            .auth-errors(v-if='(errors && errors.length) || errorMessage')
                h5.error {{ errorMessage }}
                ul(v-if='errors.length')
                    li.h5.error(
                        :key='error.message',
                        v-for='error in errors'
                    ) {{ error.message }}
                        ul(v-if='error.items')
                            li.h5.error(
                                :key='message',
                                v-for='message in error.items'
                            ) {{ message }}
            .form-group
                label.h3(for='email') Email
                input#email.h3(
                    :class='{ "has-error": emailErrors[0] }',
                    @blur='$v.userEmail.$touch()',
                    @input='$v.userEmail.$touch()',
                    autofocus,
                    data-cy='emailInput',
                    placeholder='name@email.com',
                    required,
                    type='text',
                    v-model='form.userEmail'
                )
                h5.error(v-if='emailErrors.length') {{ emailErrors[0] }}
            .form-group
                label.h3(for='password') {{ $t('onboarding.password') }}
                input#password.h3(
                    :class='{ "has-error": passwordErrors[0] }',
                    @blur='$v.password.$touch()',
                    @input='$v.password.$touch()',
                    data-cy='passwordInput',
                    placeholder='************',
                    required,
                    type='password',
                    v-model='form.password'
                )
                h5.error(v-if='passwordErrors.length') {{ passwordErrors[0] }}
            KetchUpButton.primary(
                :disabled='submitting',
                data-cy='registerUser'
            )
                LoadingSpinner(v-if='submitting')
                h3(v-else) {{ $t('onboarding.signUpButtonText') }}
            .rule
                hr
                span.h4 {{ $t('or') }}
            .oauth-connections
                .facebook(
                    @click='signInWithFacebookConnection',
                    v-if='false'
                )
                    img(:src='facebookAvatar')
                    h4 {{ $t('onboarding.facebookSignup') }}
                .google(@click='signInWithGoogleConnection')
                    img(:src='googleAvatar')
                    h4 {{ $t('onboarding.googleSignup') }}
            .terms
                h5
                    span {{ $t('onboarding.termsCopy') }}
                    a(
                        href='https://www.ketchup.academy/nutzungsbedingungen',
                        target='_blank'
                    ) {{ $t('onboarding.termsOfService') }}
                    span {{ $t('onboarding.andthe') }}
                    a(
                        href='https://www.ketchup.academy/privacy',
                        target='_blank'
                    ) {{ $t('onboarding.privacyPolicy') }}
            .rule(v-if='showAdditionalLoginAction')
                hr
                span.h4 {{ $t('or') }}
            h4.login-here.ketch-flex.ketch-items-center.ketch-space-x-c5(v-if='showAdditionalLoginAction')
                span {{ $t('onboarding.alreadyHaveAnAccount') }}
                span.ketch-underline
                    router-link(:to='{ name: "Login" }') {{ $t('onboarding.loginHere') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useIcons from '@/composables/useIcons'
  import Auth0Api from '@/services/api/Auth0Api'
  import useAuthentication from '@/composables/useAuthentication'
  import CookieHelper from '@/helpers/CookieHelper'
  import useI18n from '@/composables/useI18n'
  import type { SignupResponse } from '@/services/interfaces/Auth0'
  import { useRoute } from 'vue-router/composables'
  import { UserModule } from '@/store/modules/user'

  const route = useRoute()

  const { replaceNonAlphanumericChars, isSachkundeCompany } = useCommonMixin()
  const { facebookAvatar, googleAvatar } = useIcons()
  const {
    validator,
    emailErrors,
    passwordErrors,
    signInWithGoogleConnection,
    signInWithFacebookConnection,
    form,
    tokenKey,
  } = useAuthentication()
  const { translateString } = useI18n()

  const errors = ref([] as { message: string; items: string[] | null }[])
  const errorMessage = ref('')
  const submitting = ref(false)

  defineProps({
    showAdditionalLoginAction: {
      type: Boolean,
      default: false,
    },
  })

  const $v = computed(() => validator.value)

  const signupDirectConnection = () => {
    $v.value.$touch()
    errors.value = []
    if (!$v.value.$invalid) {
      submitting.value = true
      Auth0Api.dbConnectionSignup(form.value.userEmail, form.value.password)
        .then(() => {
          Auth0Api.dbConnectionSignin(form.value.userEmail, form.value.password)
            .then(async (data) => {
              await CookieHelper.setCrossDomainCookie(tokenKey.value, data.access_token)
              if (process.env.APP_ENV === 'production') {
                ;(window as any).top.postMessage({ type: 'redirectAfterSignup', accessToken: data.access_token }, '*')
              }
              const testDomainParams = route.query.testDomain
              if (testDomainParams === 'dev') {
                window.location.href = '/profile-page?testDomain=dev'
              } else {
                await routeToProfilePage()
              }
            })
            .catch(() => {
              submitting.value = false
              errorMessage.value = translateString('onboarding.errors.access_denied') as string
            })
        })
        .catch((err) => {
          submitting.value = false
          if (err.response?.data) {
            let key
            if (err.response.data.message) {
              key = replaceNonAlphanumericChars(err.response.data.message, '')
              handleSignupResponse(err.response.data)
            } else {
              key = replaceNonAlphanumericChars(err.response.data.description, '')
            }
            errorMessage.value = translateString(`onboarding.errors.${key}`) as string
          }
        })
    }
  }

  const handleSignupResponse = (response: SignupResponse) => {
    switch (response.code) {
      case 'invalid_password':
        if (typeof response.description === 'object') {
          errors.value.push(
            ...response.description.rules.map((rule) => {
              return {
                message: formatMessage(rule.message, rule.format),
                items:
                  rule.items?.map((item) => {
                    const key = replaceNonAlphanumericChars(item.message, '')
                    return translateString(`onboarding.errors.${key}`) as string
                  }) || null,
              }
            }),
          )
        }
        return
      default:
        errors.value.push({ message: response.description as string, items: null })
    }
  }

  const formatMessage = (message: string, format: number[]) => {
    format.forEach((f) => {
      message = message.replace('%d', f.toString())
    })
    const key = replaceNonAlphanumericChars(message, '')
    return translateString(`onboarding.errors.${key}`) as string
  }

  const routeToProfilePage = async () => {
    if (isSachkundeCompany.value && route.name === 'CustomRegistration') {
      // this is for the new SKG signup flow
      await UserModule.getUser()
      return
    }

    /*
     check for search params if present
     this could include UTM params that we want to capture
     */
    const urlParams = new URLSearchParams(window.location.search)

    const protocol = window.location.protocol
    const host = window.location.host

    const onboardingUrl = `${protocol}//${host}/profile-page`
    const newUrl = new URL(onboardingUrl)

    newUrl.search = urlParams.toString()
    window.location.href = newUrl.toString()
  }
</script>

<style lang="postcss">
  .signup-wrapper {
    @apply ketch-py-c30 xs4:ketch-py-c60 xl:ketch-py-c100 ketch-overflow-hidden;
    .login-container {
      @apply ketch-text-right;
      span {
        @apply ketch-text-green-500 ketch-underline ketch-font-bold ketch-cursor-pointer;
      }
    }
    .signup-form {
      @apply ketch-text-gray-700 ketch-bg-white;
      .form-wrapper {
        @apply ketch-space-y-c20;
        .loading .dot {
          @apply ketch-bg-white;
        }
        .auth-errors {
          @apply ketch-p-c20 ketch-border ketch-border-red-500 ketch-rounded-normal;
          > ul {
            @apply ketch-mt-c10;
          }
          .error {
            @apply ketch-text-red-500;
          }
          ul {
            @apply ketch-list-disc ketch-pl-c30;
          }
        }
        .form-group {
          @apply ketch-flex ketch-flex-col;
          label {
            @apply ketch-mb-c5;
          }
          input {
            @apply ketch-border ketch-px-c15 ketch-py-c10 ketch-rounded-small focus:ketch-border-gray-700;
            @apply ketch-outline-none;
          }
          .error {
            @apply ketch-text-red-500;
          }
          .has-error {
            @apply ketch-border-red-500;
          }
        }
        .terms {
          @apply ketch-text-center ketch-text-gray-400 ketch-py-c10;
          a {
            @apply ketch-text-blue-500 ketch-underline ketch-inline-block;
          }
          span {
            @apply ketch-mx-c5;
          }
        }
        button {
          @apply ketch-w-full ketch-rounded-none ketch-border-none ketch-h-c50;
        }
        .rule {
          @apply ketch-relative ketch-py-c15;
          span {
            @apply ketch-absolute ketch-px-c20 ketch-bg-white ketch-bottom-c5;
            left: calc(50% - 28px);
          }
        }
        .oauth-connections {
          .google,
          .facebook {
            @apply ketch-flex ketch-items-center ketch-px-c5 ketch-border ketch-rounded-small ketch-cursor-pointer;
            img {
              @apply ketch-mr-c2 ketch-w-c35 ketch-h-c35;
            }
          }
          .google {
            @apply hover:ketch-border-gray-400;
          }
          .facebook {
            @apply ketch-text-white ketch-mb-c10 hover:ketch-bg-blue-900;
            background-color: #3b5998;
            img {
              @apply ketch-w-c45 ketch-h-c45 ketch-p-c10;
            }
          }
        }
      }
    }
  }
</style>
