<template lang="pug">
.chrome-tab-extension
    KetchUpHeader(:is-extension='true')
    .content-area(v-if='isValidUser')
        main(v-if='question')
            h6.question-from
                span {{ $t('homepage.questionFrom') }}
                a(:href='`${BASE_URL}/courses/${currentQuestionCourse.id}/info`') {{ currentQuestionCourse.title }}
            QuestionWithButtons(
                :in-results-mode='inResultsMode',
                :is-guided-quiz='true',
                :parent-course-id='currentQuestionCourse.id',
                :questions='[question]',
                :show-button-slot='true',
                :show-previous-button='false',
                :show-question-number='false',
                @submit='fetchNextQuestion'
            )
                template(
                    v-if='!isPurchasedCourse(currentQuestionCourse.id)',
                    v-slot:button-text
                )
                    a.button-text(:href='`${BASE_URL}/courses/${currentQuestionCourse.id}/checkout`')
                        span {{ $t('unlockAllQuestions', { totalQuestions: question.courseMeta.totalNumberOfQuestions }) }}
        .footer
            .choose-copy(v-if='question === null')
                h3(v-html='$t("browserExtension.chooseQuestionAreas")')
                SVGRenderer(
                    :fill-color='"var(--primary-text-color)"',
                    :has-hover='false',
                    :icon='curvedDownArrow2'
                )
            h6 {{ $t('questionFromAreas') }}
            .areas
                Choice.course(
                    :key='course.id',
                    :no-border='true',
                    :selected='course.selected',
                    :svg-renderer-props='{ icon: course.selected ? checkedCheckboxSquareWhite : checkboxSquareWhite, strokeColor: "var(--secondary-color)", width: "20" }',
                    :title='course.area',
                    @click.native='onCourseClick(course.id)',
                    v-for='course in courses'
                )
    .auth-wrapper(v-else)
        h3(v-html='$t("browserExtension.registerForQuestions")')
        .auth-items
            .item(
                :key='item.url',
                v-for='item in authItems'
            )
                h4(v-html='item.description')
                KetchUpButton.tertiary(@click.stop.native='onAuthButtonClick(item)')
                    h5 {{ item.buttonText }}
                    SVGRenderer(
                        :has-hover='false',
                        :icon='arrowRight',
                        stroke-color='var(--primary-text-color)'
                    )
        .copy
            h5 {{ $t('browserExtension.freeOfferMessage') }}
            h5 {{ $t('browserExtension.companyTitle') }}
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import KetchUpHeader from '@/components/KetchUpHeader.vue'
  import QuestionWithButtons from '@/components/common/QuestionWithButtons.vue'
  import useIcons from '@/composables/useIcons'
  import Choice from '@/components/common/Choice.vue'
  import CourseApi from '@/services/api/CourseApi'
  import useCourse from '@/composables/useCourse'
  import { UserModule } from '@/store/modules/user'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import { CourseModule } from '@/store/modules/course'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useGamification from '@/composables/useGamification'
  import CookieHelper from '@/helpers/CookieHelper'
  import useAuthentication from '@/composables/useAuthentication'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { Nullable, Question, QuestionAnswer } from '@/services/interfaces/QuizQuestion'

  interface CourseSelection {
    id: string
    title: string
    area: string
    selected: boolean
  }

  interface AuthItem {
    id: string
    url: string
    description: string
    buttonText: string
  }

  const { checkedCheckboxSquareWhite, checkboxSquareWhite, arrowRight, curvedDownArrow2 } = useIcons()
  const { fetchAllCourseModules, showStartCourseForFreeModal } = useCourse()
  const { convertNumberAnswer } = useCommonMixin()
  const { trackGamificationActivity } = useGamification()
  const { tokenKey } = useAuthentication()
  const { translateString } = useI18n()

  const BASE_URL = window.origin
  const TAB_COURSES = 'tab_courses_'
  const courses = ref([
    {
      id: '63d7d5bb-e8e9-429d-b360-cefdecd80448',
      title: 'Vorbereitung auf die IHK-Sachkundeprüfung Immobiliardarlehensvermittlung - § 34i (schriftlich)',
      area: '$ 34i GewO',
      selected: false,
    },
    {
      id: '8de44221-a055-4cdb-8ce5-d7ac148cb49e',
      title: 'Vorbereitung auf die IHK-Sachkundeprüfung Versicherungsvermittlung - § 34d (schriftlich)',
      area: '$ 34d GewO',
      selected: false,
    },
    {
      id: '84be496a-b904-4184-b456-98736fea9259',
      title: 'Vorbereitung auf die IHK- Sachkundeprüfung Finanzanlagenvermittlung - § 34f (schriftlich)',
      area: '$ 34f GewO',
      selected: false,
    },
  ] as CourseSelection[])
  const authItems: AuthItem[] = [
    {
      id: 'login',
      url: '/login?redirect=/chrome-extension',
      description: translateString('browserExtension.loginDescription'),
      buttonText: translateString('browserExtension.loginButtonText'),
    },
    {
      id: 'register',
      url: 'https://sachkunde.ketchup.academy/registrierung',
      description: translateString('browserExtension.registerDescription'),
      buttonText: translateString('browserExtension.registerButtonText'),
    },
  ]

  const openPopup = (url: string): Window => {
    const width = 1200
    const height = 960
    const left = window.screenX + (window.innerWidth - width) / 2
    const top = window.screenY + (window.innerHeight - height) / 2

    return window.open(
      url,
      'ketchup:register:popup',
      `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`,
    ) as Window
  }

  const onAuthButtonClick = (item: AuthItem) => {
    if (item.id === 'register') {
      const registerWindow = openPopup(item.url)
      window.addEventListener('message', (e: MessageEvent) => {
        if (e.origin === 'https://sachkunde.ketchup.academy') {
          CookieHelper.setCrossDomainCookie(tokenKey.value, e.data)
          registerWindow.close()
          window.location.reload()
        }
      })
      return
    }
    eventBus.$auth.loginWithPopup({
      redirect_uri: `${window.location.origin}/login/callback?redirect=/chrome-extension`,
    })
  }
  const isCourseTrialStarted = computed(
    () => (courseId: string) => !!CourseModule.courses?.find((c) => c.id === courseId && c.trial),
  )
  const isPurchasedCourse = computed(
    () => (courseId: string) => !!CourseModule.courses?.find((c) => c.id === courseId && c.purchased),
  )

  const isValidUser = computed(() => UserModule.hasValidUser)

  if (isValidUser.value) fetchAllCourseModules(courses.value.map((c) => c.id))

  const updateLocalStorageValue = () => {
    const userCourseIndices = courses.value.map((c) => c.selected)
    localStorage.setItem(TAB_COURSES + UserModule.id, JSON.stringify(userCourseIndices))
  }

  onMounted(() => {
    const userCourseIndices = localStorage.getItem(TAB_COURSES + UserModule.id)
    if (userCourseIndices) {
      courses.value.forEach((course, index) => {
        course.selected = JSON.parse(userCourseIndices)[index]
      })
    }
    if (isValidUser.value) fetchNextQuestion()
  })

  const currentQuestionCourse = computed(() => {
    if (!question.value) return null
    return courses.value.find((course) => course.id === question.value!.courseMeta?.courseId)
  })

  const onCourseClick = (courseId: string) => {
    const selectedCourse = courses.value.find((course) => course.id === courseId) as CourseSelection
    if (!(isCourseTrialStarted.value(selectedCourse.id) || isPurchasedCourse.value(selectedCourse.id))) {
      showStartCourseForFreeModal(selectedCourse.id, false, () => {
        handleSelectedCourseCallback(selectedCourse)
      })
      return
    }
    handleSelectedCourseCallback(selectedCourse)
  }

  const handleSelectedCourseCallback = async (selectedCourse: CourseSelection) => {
    selectedCourse.selected = !selectedCourse.selected
    updateLocalStorageValue()
    const selectedCourses = courses.value.filter((c) => c.selected).map((c) => c.id)
    if (!selectedCourses.length) {
      question.value = null
      return
    }
    if (question.value === null) {
      question.value = await CourseApi.getRandomQuestion([selectedCourse.id])
    }
  }

  const question = ref(null as Nullable<Question>)
  const inResultsMode = ref(false)
  const fetchNextQuestion = async (answers?: QuestionAnswer[]) => {
    if (inResultsMode.value || !answers) {
      const courseIds = courses.value.filter((course) => course.selected).map((course) => course.id)
      if (courseIds.length) {
        question.value = await CourseApi.getRandomQuestion(courseIds)
      }
      inResultsMode.value = false
      eventBus.$emit('reset-background-tile-height')
      return
    }
    if (answers?.length && !inResultsMode.value) {
      await CourseApi.postRandomQuestionAnswer(
        question.value!.lesson!.id,
        answers[0].questionId,
        answers[0].selectedAnswerIds,
      )
      inResultsMode.value = true
      handleGamificationActivity(answers)
    }
  }

  const currentCorrectAnswers = computed(() => question.value?.answers.filter((ans) => ans.isCorrect) || [])
  const handleGamificationActivity = (answers: QuestionAnswer[]) => {
    let questionAnsweredCorrectly = false
    if (question.value?.questionType === 'number') {
      const numberAnswer = convertNumberAnswer(answers[0].selectedAnswerIds[0] || '')
      questionAnsweredCorrectly = numberAnswer === currentCorrectAnswers.value[0].answer
    } else if (
      currentCorrectAnswers.value?.length === answers[0].selectedAnswerIds.length &&
      currentCorrectAnswers.value?.every((answer) => answers[0].selectedAnswerIds.includes(answer.answerId))
    ) {
      questionAnsweredCorrectly = true
    }

    if (questionAnsweredCorrectly) {
      trackGamificationActivity('RANDOM_QUESTION_ANSWERED_CORRECTLY', {
        courseId: currentQuestionCourse.value?.id || '',
        moduleId: questionModule.value?.id || '',
        lessonId: question.value?.lesson?.id || null,
      })
    }
  }

  const questionModule = computed(() => {
    const courseModules = CourseModule.coursesModuleMap[currentQuestionCourse.value?.id || '']
    if (!courseModules) return
    return courseModules.find((m) => m.lessons.find((l) => l.id === question.value?.lesson?.id || ''))
  })
</script>

<style lang="postcss">
  .chrome-tab-extension {
    @apply ketch-relative ketch-z-[1] ketch-h-screen;
    .content-area {
      @apply ketch-w-[540px] ketch-mx-auto ketch-py-c60;
      main {
        @apply ketch-flex ketch-flex-col ketch-justify-center ketch-relative ketch-z-[1];
      }
    }
    .auth-wrapper {
      @apply ketch-w-[600px] ketch-mx-auto ketch-pt-c150;
      span,
      button h5 {
        @apply ketch-font-bold;
      }
      .auth-items {
        @apply ketch-mt-c20 ketch-flex ketch-space-x-c60 ketch-h-[144px] ketch-relative;
        .item {
          @apply ketch-border ketch-border-secondary-color ketch-rounded-normal ketch-p-c20;
          @apply ketch-bg-secondary-color ketch-bg-opacity-5 ketch-flex ketch-flex-col ketch-justify-between;
          button {
            @apply ketch-space-x-c12 ketch-w-[130px];
          }
          &:last-of-type button {
            @apply ketch-w-[180px];
          }
        }
        &:before {
          @apply ketch-w-c1 ketch-absolute ketch-top-0 ketch-bottom-0 ketch-left-[calc(50%+2px)];
          content: '';
          background: linear-gradient(
            to bottom,
            rgba(80, 80, 80, 0) 0%,
            #d0d0d0 8%,
            #d0d0d0 92%,
            rgba(80, 80, 80, 0) 100%
          );
        }
      }
      .copy {
        @apply ketch-mt-c30;
        h5 {
          @apply ketch-text-opacity-50 ketch-text-primary-text-color;
        }
      }
    }
    .question-from {
      @apply ketch-space-x-c5;
      span {
        @apply ketch-inline-block;
      }
      a {
        @apply ketch-underline ketch-text-blue-500;
      }
    }
    .question-with-buttons {
      @apply ketch-max-w-full;
      .button-text {
        @apply ketch-underline ketch-text-blue-500 ketch-font-bold;
      }
      .comment-area {
        @apply ketch-w-full;
      }
    }
    .footer {
      @apply ketch-fixed ketch-bg-white ketch-bottom-0 ketch-w-[540px] ketch-border-t ketch-border-border-color;
      @apply ketch-flex ketch-items-center ketch-justify-between ketch-py-c10 ketch-mx-auto ketch-z-[2];
      .choose-copy {
        @apply ketch-max-w-[400px] ketch-absolute ketch-top-[-160px];
        h3 span {
          @apply ketch-font-bold;
        }
        svg {
          @apply ketch-absolute ketch-right-0 ketch-pt-c10;
        }
      }
      .areas {
        @apply ketch-flex ketch-space-x-c20 ketch-ml-c30;
        .course {
          @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-p-0;
          svg {
            @apply ketch-w-c20;
          }
          &:not(.selected) {
            @apply ketch-opacity-40;
          }
          h5 {
            @apply ketch-whitespace-nowrap;
          }
        }
      }
    }
  }
</style>
