import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import QuizResults from '@/views/QuizResults.vue'
import SearchResults from '@/views/SearchResults.vue'
import Bookmarks from '@/views/Bookmarks.vue'
import AuthOperations from '@/components/AuthOperations.vue'
import ProfilePage from '@/views/ProfilePage.vue'
import Account from '@/views/Account.vue'
import { UserModule } from '@/store/modules/user'
import FlagsPanel from '@/views/FlagsPanel.vue'
import Checkout from '@/components/checkout/Checkout.vue'
import CheckoutSuccess from '@/components/checkout/CheckoutSuccess.vue'
import Billing from '@/views/Billing.vue'
import CourseLayout from '@/views/layouts/CourseLayout.vue'
import CourseInfo from '@/components/course/CourseInfo.vue'
import CourseModule from '@/views/CourseModule.vue'
import Lesson from '@/views/Lesson.vue'
import LessonQuizQuestion from '@/views/LessonQuizQuestion.vue'
import CourseStartPage from '@/views/CourseStartPage.vue'
import GuidedQuiz from '@/views/GuidedQuiz.vue'
import ModuleLessonLayout from '@/views/layouts/ModuleLessonLayout.vue'
import DownloadMaterials from '@/components/course/DownloadMaterials.vue'
import Library from '@/views/Library.vue'
import ExamStartPage from '@/components/course/ExamStartPage.vue'
import CourseExam from '@/views/CourseExam.vue'
import PackageLayout from '@/views/layouts/PackageLayout.vue'
import PackageInfo from '@/components/course/PackageInfo.vue'
import CourseTrialPage from '@/views/CourseTrialPage.vue'
import CustomRegistration from '@/views/CustomRegistration.vue'
import AllNews from '@/views/AllNews.vue'
import PromotedCourse from '@/views/PromotedCourse.vue'
import ChromeTabExtension from '@/views/ChromeTabExtension.vue'
import Trainings from '@/views/Trainings.vue'
import CompanyAdminLayout from '@/views/layouts/CompanyAdminLayout.vue'
import EditGamification from '@/components/editor/EditGamification.vue'
import CompanySelection from '@/views/CompanySelection.vue'
import Login from '@/views/Login.vue'
import UsersAndGroups from '@/views/UsersAndGroups.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import CookieHelper from '../helpers/CookieHelper'
import auth_config from '../../auth_config.json'
import FollowupTable from '@/components/course/followup/FollowupTable.vue'
import MixpanelAnalytics from '@/views/MixpanelAnalytics.vue'
import Maintenance from '@/views/Maintenance.vue'
import KnowledgeCheckInfo from '@/components/course/KnowledgeCheckInfo.vue'
import SetPrimaryEmail from '@/views/SetPrimaryEmail.vue'

const { staging, production } = auth_config

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/trainings',
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: {
      hideNav: true,
      hideAdminNav: true,
      hideFooter: true,
    },
  },
  {
    path: '/trainings',
    name: 'Trainings',
    component: Trainings,
    meta: {
      hideBackgroundTile: true,
      showCompanyHeader: true,
      showGetStartedPanelOrCourseTrials: true,
    },
  },
  {
    path: '/company-selection',
    name: 'CompanySelection',
    component: CompanySelection,
    meta: {
      requiresAuth: true,
      hideBackgroundTile: true,
      hideHeaderElements: true,
      hideAdminNav: true,
    },
  },
  {
    path: '/admin',
    component: CompanyAdminLayout,
    children: [
      {
        path: '/',
        redirect: '/admin/users-and-groups',
      },
      {
        path: 'gamification',
        component: EditGamification,
        meta: {
          requiresAuth: true,
          hideBackgroundTile: true,
        },
      },
      {
        path: 'users-and-groups',
        name: 'UsersAndGroups',
        component: UsersAndGroups,
        meta: {
          requiresAuth: true,
          hideBackgroundTile: true,
        },
      },
      {
        path: 'followups',
        component: FollowupTable,
        meta: {
          requiresAuth: true,
          hideBackgroundTile: true,
        },
      },
      {
        path: 'analytics',
        component: MixpanelAnalytics,
        meta: {
          requiresAuth: true,
          hideBackgroundTile: true,
        },
      },
    ],
  },
  {
    path: '/library',
    name: 'Library',
    component: Library,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: SearchResults,
  },
  {
    path: '/flags',
    name: 'FlagsPanel',
    component: FlagsPanel,
    meta: {
      hideAdminNav: true,
      hideFooter: true,
      requiresAuth: true,
    },
  },
  {
    path: '/bookmarks',
    name: 'AllUserBookmarks',
    component: Bookmarks,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile-page',
    name: 'ProfilePage',
    component: ProfilePage,
    meta: {
      hideNav: true,
      hideAdminNav: true,
      noPadding: true,
    },
  },
  {
    path: '/promoted-course',
    component: PromotedCourse,
    name: 'PromotedCourse',
    meta: {
      hideNav: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: AuthOperations,
  },
  {
    path: '/registrierung',
    component: CustomRegistration,
    name: 'CustomRegistration',
    meta: {
      hideNav: true,
      hideAdminNav: true,
      hideFooter: true,
      hideBackgroundTile: true,
      centerComponent: true,
      noPadding: true,
    },
  },
  {
    path: '/chrome-extension',
    component: ChromeTabExtension,
    name: 'ChromeTabExtension',
    meta: {
      hideNav: true,
      hideFooter: true,
      noPadding: true,
    },
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/packages',
    component: PackageLayout,
    name: 'PackageLayout',
    children: [
      {
        path: ':packageId',
        redirect: '/packages/:packageId/info',
      },
      {
        path: ':packageId/info',
        name: 'PackageDetails',
        component: PackageInfo,
      },
      {
        path: ':packageId/checkout',
        name: 'PackageCheckout',
        component: Checkout,
        meta: {
          requiresAuth: true,
          hideCourseBreadcrumb: true,
        },
      },
      {
        path: ':packageId/checkout/success',
        name: 'PackageCheckoutSuccess',
        component: CheckoutSuccess,
        meta: {
          requiresAuth: true,
          hideCourseBreadcrumb: true,
        },
      },
    ],
  },
  {
    path: '/trial-selector',
    name: 'CourseTrialPage',
    component: CourseTrialPage,
    meta: {
      hideNav: true,
      hideFooter: true,
      hideAdminNav: true,
      requiresAuth: true,
      centerComponent: true,
      noPadding: true,
    },
  },
  {
    path: '/courses',
    component: CourseLayout,
    name: 'CourseLayout',
    props: (route) => ({ courseId: route.params.courseId }),
    children: [
      {
        path: ':courseId',
        redirect: '/courses/:courseId/info',
      },
      {
        path: ':courseId/info',
        name: 'CourseDetails',
        component: CourseInfo,
      },
      {
        path: ':courseId/check',
        name: 'KnowledgeCheckInfo',
        component: KnowledgeCheckInfo,
        meta: {
          hideAdminNav: true,
          hideCourseBreadcrumb: true,
        },
      },
      {
        path: ':courseId/download-materials',
        component: ModuleLessonLayout,
        children: [
          {
            path: '/',
            name: 'DownloadMaterials',
            component: DownloadMaterials,
          },
        ],
      },
      {
        path: ':courseId/exam/:examId',
        component: CourseExam,
        children: [
          {
            path: '/',
            name: 'CourseExamContent',
            meta: {
              hideNav: true,
              hideCourseBreadcrumb: true,
              hideFooter: true,
              hideAdminNav: true,
            },
          },
          {
            path: 'review/:completionId',
            name: 'CourseExamReviewAnswers',
            meta: {
              hideNav: true,
              hideCourseBreadcrumb: true,
              hideFooter: true,
              hideAdminNav: true,
            },
          },
          {
            path: 'results/:completionId',
            name: 'CourseExamResults',
            meta: {
              hideNav: true,
              hideCourseBreadcrumb: true,
              hideFooter: true,
              hideAdminNav: true,
            },
          },
        ],
      },
      {
        path: ':courseId/exam/:examId',
        name: 'ExamLayout',
        component: ModuleLessonLayout,
        children: [
          {
            path: 'start',
            name: 'ExamStartPage',
            component: ExamStartPage,
          },
        ],
      },
      {
        path: ':courseId/checkout',
        name: 'CourseCheckout',
        component: Checkout,
        meta: {
          requiresAuth: true,
          hideCourseBreadcrumb: true,
        },
      },
      {
        path: ':courseId/checkout/success',
        name: 'OneOffCheckoutSuccess',
        component: CheckoutSuccess,
        meta: {
          requiresAuth: true,
          hideCourseBreadcrumb: true,
        },
      },
      {
        path: ':courseId/module',
        name: 'ModuleLessonLayout',
        component: ModuleLessonLayout,
        children: [
          {
            path: ':moduleId',
            name: 'CourseModule',
            component: CourseModule,
            props: true,
          },
          {
            path: ':moduleId/start',
            name: 'CourseModuleStartPage',
            component: CourseStartPage,
          },
          {
            path: ':moduleId/guided-quiz',
            name: 'GuidedQuiz',
            component: GuidedQuiz,
          },
          {
            path: ':moduleId/lesson/:lessonId',
            name: 'Lesson',
            component: Lesson,
          },
          {
            path: ':moduleId/lesson/:lessonId/question/:questionId',
            name: 'LessonQuizQuestion',
            component: LessonQuizQuestion,
          },
          {
            path: ':moduleId/lesson/:lessonId/results',
            name: 'LessonQuizResults',
            component: QuizResults,
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: '/news',
    component: AllNews,
    name: 'AllNews',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      hideBackgroundTile: true,
      noPadding: true,
      centerComponent: true,
      hideHeaderElements: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      hideBackgroundTile: true,
      noPadding: true,
      centerComponent: true,
      hideHeaderElements: true,
      hideFooter: true,
    },
  },
  {
    path: '/login/callback',
    name: 'LoginCallback',
    component: AuthOperations,
  },
  {
    path: '/set-primary-email/:pendingOrderId',
    name: 'SetPrimaryEmail',
    component: SetPrimaryEmail,
    meta: {
      hideNav: true,
      hideAdminNav: true,
      hideFooter: true,
      centerComponent: true,
      noPadding: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (
      to.matched.some((route) =>
        [
          'ModuleLessonLayout',
          'ExamLayout',
          'DownloadMaterials',
          'CourseDetails',
          'ChromeTabExtension',
          'AllUserBookmarks',
          'PackageCheckout',
          'CourseCheckout',
        ].includes(route.name as string),
      )
    ) {
      return { x: 0, y: 0 }
    }
    return
  },
})

const onAuthRequired = async (to: Route, from: Route, next: NavigationGuardNext) => {
  const re = new RegExp(/^\/start\/{0,1}$/)
  if (re.test(to.path)) return next('/trainings')

  const requiresAuth = to.matched.some((record: any) => record.meta.requiresAuth)
  if (requiresAuth) {
    if (!UserModule.hasValidUser) {
      return next({ path: `/login?redirect=${to.fullPath}` })
    }
  }
  const tokenKey = process.env.APP_ENV === 'production' ? production.tokenKey : staging.tokenKey
  const token = CookieHelper.getCookieValue(tokenKey)
  if (token && to.name === 'Login') return next({ path: '/' })

  return next()
}

router.beforeEach(onAuthRequired)

export default router
